body {
  background: #282a2e;
  color: #f3f3f3;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Fira Sans', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Cream Cake';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/Cream Cake.otf'); /* IE9 Compat Modes */
  src: local('Cream Cake'), local('Cream Cake'),
       url('./assets/Cream Cake.otf') format('otf'), /* Modern Browsers */
}


.form-sizer {
  width: 40%;
  margin-left: auto;
  margin-right: auto;
}

/* LOGIN PAGE */
.login-heading {
  width: 100%;
  text-align: center;
  font-family: 'Cream Cake';
}

.home-styles {
  /*font-family: 'Fira Mono', monospace;*/
  font-family: 'Open Sans', serif;

}
.nav-logo {
  font-family: 'Cream Cake' !important;
  font-size: 32px !important;
  color: #6FFFB0 !important;
}
.profile {
  width: 100%;
  display: inline-grid;
  grid-template-columns: repeat(2, 50% [col-start]);
  border-bottom: 1px solid #81FCED;
}
.user {
  width: 45%;
}
.info {
  width: 55%;
  word-wrap: break-word;
}
.edit {
  width: 10%;
}
